import {
  Heading,
  Box,
  Text,
  Stack,
  SimpleGrid,
  Card,
  CardBody,
} from "@chakra-ui/react";

import { ACADEMY_CONTENT } from "../../configuration/academy-content";

export function AcademyContent() {
  return (
    <Box 
    className="my-history-box" 
    marginTop={{ base: "0vw", md: "1vw" }}
    >
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Contenido de la formación<span style={{ color: "#f10965" }}>:</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={{ base: 8, md: 10 }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "15vh", md: "15vh" }}
        marginLeft={{ base: "5%", md: "5%" }}
        marginRight={{ base: "5%", md: "5%" }}
      >
        {ACADEMY_CONTENT.map((post) => (
          <Stack spacing={4}>
              <Card
                borderRadius="6"
                boxShadow={"xl"}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
              >
                <Stack>
                  <CardBody minHeight={ "250px" }>
                    <Heading size="md" className="benefits-heading" color={post.color_title}>{post.title}</Heading>
                    <Text py="2">{post.description}</Text>
                  </CardBody>
                </Stack>
              </Card>
          </Stack>
        ))}
      </SimpleGrid>
    </Box>
  );
}
