import React from 'react';
import { Fragment } from "react";
import { Footer } from "../components/footer";
import { Header } from "../components/header";
import { MyStory } from "../components/my-story";
import { WhatIdo } from "../components/what-I-do";
import { LatestPost } from "../components/latest-post";
import { WhereITalk } from "../components/where-I-Talk";
import { WithSubnavigation } from "../components/navbar";
import { WantToTalk } from "../components/want-to-talk";
import { Helmet } from 'react-helmet'; 
import { ChangeDocumentTitle } from '../Utils/Utilities';

export function Landing() {
  ChangeDocumentTitle("Leonardo Poza")
  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Leonardo Poza" data-react-helmet="true"/>
        <meta property="og:description" content="Contratar no es sencillo y perfiles técnicos, menos aún. Tienes miedo a equivocarte y muchas veces no sabes por donde empezar. Yo ayudé a una fintech a duplicar el equipo de desarrollo en un año, pasando de 35 personas a más de 70. Me apasiono y ahora estoy cambiando la forma de contratar perfiles técnicos." data-react-helmet="true"/>
        <meta property="og:image" content="https://leonardopoza.com/yo.avif" data-react-helmet="true" />
        <meta property="og:url" content="https://leonardopoza.com/" data-react-helmet="true"/>
        <meta property="og:type" content="website" data-react-helmet="true"/>
  
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
        <meta name="twitter:title" content="Leonardo Poza" data-react-helmet="true"/>
        <meta name="twitter:description" content="Contratar no es sencillo y perfiles técnicos, menos aún. Tienes miedo a equivocarte y muchas veces no sabes por donde empezar. Yo ayudé a una fintech a duplicar el equipo de desarrollo en un año, pasando de 35 personas a más de 70. Me apasiono y ahora estoy cambiando la forma de contratar perfiles técnicos." data-react-helmet="true"/>
        <meta name="twitter:image" content="https://leonardopoza.com/yo.avif" data-react-helmet="true"/>
      </Helmet>
      <WithSubnavigation />
      <Header />
      <MyStory />
      <WhatIdo />
      <LatestPost />
      <WhereITalk />
      <WantToTalk />
      <Footer />
    </Fragment>
  );
}
