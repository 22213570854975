import React from 'react';
import { Fragment } from "react";
import { Heading } from "@chakra-ui/react";
import { Footer } from "../components/footer";
import { WithSubnavigation } from "../components/navbar";
import { TheTeacher } from "../components/academy/academy-teacher";
import { AcademyValue } from "../components/academy/academy-value";
import { AcademyFormats } from "../components/academy/academy-formats";
import { AcademyContent } from "../components/academy/academy-content";
import { NextEvent } from "../components/academy/academy-next-event";
import { Helmet } from 'react-helmet'; 
import { ChangeDocumentTitle } from '../Utils/Utilities';

export function Academy() {
  ChangeDocumentTitle("Leonardo Poza - Recruiting Tech Academy")
  return (
    <Fragment>
      <Helmet>
        <meta property="og:title" content="Leonardo Poza - Recruiting Tech Academy" data-react-helmet="true"/>
        <meta property="og:description" content="Contratar no es sencillo y perfiles técnicos, menos aún. Tienes miedo a equivocarte y muchas veces no sabes por donde empezar. Yo ayudé a una fintech a duplicar el equipo de desarrollo en un año, pasando de 35 personas a más de 70. Me apasiono y ahora estoy cambiando la forma de contratar perfiles técnicos." data-react-helmet="true"/>
        <meta property="og:image" content="https://leonardopoza.com/header_image_sharing.jpg" data-react-helmet="true" />
        <meta property="og:url" content="https://leonardopoza.com/recruiting-academy/" data-react-helmet="true"/>
        <meta property="og:type" content="website" data-react-helmet="true"/>
  
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
        <meta name="twitter:title" content="Leonardo Poza - Recruiting Tech Academy" data-react-helmet="true"/>
        <meta name="twitter:description" content="Contratar no es sencillo y perfiles técnicos, menos aún. Tienes miedo a equivocarte y muchas veces no sabes por donde empezar. Yo ayudé a una fintech a duplicar el equipo de desarrollo en un año, pasando de 35 personas a más de 70. Me apasiono y ahora estoy cambiando la forma de contratar perfiles técnicos." data-react-helmet="true"/>
        <meta name="twitter:image" content="https://leonardopoza.com/header_image_sharing.jpg" data-react-helmet="true"/>
      </Helmet>
      <WithSubnavigation />
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        marginBottom={{ base: "2vh", md: "5vh" }}
        fontSize={{ base: "9vh", md: "12vh" }}
      >
        Taller de Recruiting IT<span style={{ color: "#66C7F4" }}>.</span>
      </Heading>

      <Heading
        className="hero-skills"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "20vh", md: "27vh" }}
      >
        #recruitment. &nbsp;&nbsp; #formación.&nbsp;&nbsp; #atracción.&nbsp;&nbsp;
        #contratación.
      </Heading>

      <NextEvent />
      <TheTeacher />
      <AcademyValue />
      <AcademyFormats />
      <AcademyContent />
      <NextEvent />
      <Footer />
    </Fragment>
  );
}
