import {
  Heading,
  Box,
  Text,
  Stack,
  VStack,
  Center,
  Badge,
  SimpleGrid,
} from "@chakra-ui/react";

export function NextEvent() {
  return (
    <Box className="my-history-box" marginTop={{ base: "0vw", md: "2vw" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vw", md: "15vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "0vw", md: "20vh" }}
      >
        <Heading
          className="contact-heading"
          fontSize={{ base: "8vh", md: "12vh" }}
          marginTop={{ base: "4vh", md: "10vh" }}
          marginBottom={{ base: "4vh", md: "10vh" }}
        >
          Próxima <br></br>edición <span style={{ color: "#FFC43D" }}>.</span>
        </Heading>
        <VStack spacing={4} align="stretch">
          <Stack m={2}>
            <a
              href="https://leonardopoza.notion.site/12efc867ce2080e08766f95ec28fb6d2"
              target={"_blank"}
              rel="noreferrer"
              className="card-zoomer-soft"
            >
              <Center>
                <Box
                  p={6}
                  w={"full"}
                  boxShadow={"2xl"}
                  rounded={"lg"}
                  pos={"relative"}
                  zIndex={1}
                  className="card"
                >
                  <Stack align={"center"}>
                    <Heading
                      align={"center"}
                      fontSize={{ base: "4vh", md: "4vh" }}
                      marginTop={{ base: "4vh", md: "4vh" }}
                      marginBottom="30px !important"
                    >
                      Taller de Recruiting IT <br></br> Vol 1.0
                    </Heading>
                    <Badge
                      colorScheme={"gray"}
                      fontSize="md"
                      marginBottom={4}
                      paddingRight={5}
                      paddingLeft={5}
                    >
                      11 Y 12 DE ENERO 2025
                    </Badge>
                    <Text
                      align={"center"}
                      color="#62676F"
                      marginTop="25px !important"
                    >
                      <span style={{ color: "#f10965" }}><b>Reserva tu plaza!</b></span> Apuntate ya y no pierdas tu plaza para la primera edición
                      de este taller. Si eres empresa y quieres realizar el
                      taller con todo tu equipo, escribeme a&nbsp;<b>
                      <a href="mailto:yo@leonardopoza.com">yo@leonardopoza.com</a></b>
                    </Text>
                  </Stack>
                </Box>
              </Center>
            </a>
          </Stack>
        </VStack>
      </SimpleGrid>
    </Box>
  );
}
