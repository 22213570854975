import { Fragment } from "react";
import { Heading } from "@chakra-ui/react";

export function Blog() {
  return (
    <Fragment>
      <Heading
        className="hero-heading"
        marginLeft={{ base: "5%", md: "19.7%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginTop={{ base: "5vh", md: "10vh" }}
        fontSize={{ base: "7vh", md: "10vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        Hola, soy Leonardo Poza<span style={{ color: "#f10965" }}>!</span>
      </Heading>
    </Fragment>
  );
}
