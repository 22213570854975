
interface tag {
  tag: string;
  background: string;
  color: string;
}

interface content {
  title: string;
  spoiler: string;
  tags: Array<tag>;
  href: string;
}

export const ACADEMY_CONTENT: Array<content> = [
  {
    title: "Introducción al reclutamiento tecnológico",
    description: "Se dará una visión general del reclutamiento en el sector tecnológico, destacando los desafíos actuales y las diferencias clave con respecto a otros sectores. Se abordarán las demandas del mercado, la competitividad por el talento y cómo las tendencias tecnológicas influyen en los procesos de contratación.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Actores en un proceso de selección",
    description: "En este módulo se identificarán y analizarán los roles clave que intervienen en un proceso de selección de perfiles tecnológicos, desde el recruiter hasta el hiring manager, pasando por los miembros del equipo técnico. Se abordará la importancia de la coordinación, así como las responsabilidades y expectativas que cada uno debe cumplir para asegurar un proceso fluido y eficiente.",
    tag: "",
    color_tag: ""
  },
  {
    title: "Definición del perfil y alineación del equipo",
    description: "Una correcta definición del perfil es esencial para garantizar que se está buscando el talento adecuado. En este módulo se enseña a alinear a los equipos de selección con los hiring managers y los equipos técnicos, garantizando que todos compartan una visión clara del tipo de candidato necesario.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "¡Redactando una oferta de empleo con sentido!",
    description: "Veremos cómo redactar ofertas de empleo atractivas que resalten en el mercado competitivo y eviten errores comunes que alejan a los candidatos.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Estrategias para atraer talento Tech",
    description: "Exploraremos las mejores formas de encontrar y atraer talento técnico, destacando las plataformas, comunidades y eventos específicos que usan los desarrolladores y otros perfiles tecnológicos. Además, se trabaja en la redacción de ofertas de empleo atractivas y claras, evitando las red flags que los candidatos identifican.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Sesión con invitados: La opinion y experiencia de candidatos/as",
    description: "Mesa redonda donde los invitados compartirán sus experiencias sobre los procesos de selección, red flags que evitan y cómo prefieren ser evaluados.",
    tag: "",
    color_tag: "",
    color_title: "#f10965"
  },
  {
    title: "Evaluación de candidatos: Cómo hacerlo bien",
    description: "Se enseñará cómo realizar una evaluación efectiva de los candidatos, tanto a nivel técnico como de habilidades blandas. Se abordarán métodos para diseñar entrevistas técnicas que simulen el trabajo real y cómo evitar los sesgos comunes que distorsionan la selección.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Automatización y herramientas para el reclutamiento ágil",
    description: "La automatización es clave para agilizar los procesos y reducir tiempos en la búsqueda de talento. Este módulo presenta las principales herramientas que se pueden integrar en el flujo de trabajo de selección, como ATS (Applicant Tracking Systems)...",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Medición y optimización del proceso de selección",
    description: "No se puede mejorar lo que no se mide. En este módulo, los asistentes aprenderán a definir indicadores clave (KPIs) que les permitirán evaluar la efectividad de su proceso de selección. Además, se abordará cómo usar los datos para iterar y optimizar continuamente.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Automatizando la Evaluación del Proceso de Selección: Implementando un NPS",
    description: "El feedback es fundamental para mejorar cualquier proceso de selección, y una de las mejores formas de medir la satisfacción de los candidatos es a través del Net Promoter Score (NPS). Se verá cómo automatizar la recolección y análisis de esta métrica, permitiendo a las empresas mejorar continuamente su proceso de contratación.",
    tag: "",
    color_tag: "",
    color_title: ""
  },
  {
    title: "Sesión con invitados: Empresas que han contratado con éxito",
    description: "Mesa redonda donde los invitados Hablarán sobre sus errores y aciertos a la hora de contratar perfiles tecnológicos, proporcionando lecciones prácticas basadas en la experiencia.",
    tag: "",
    color_tag: "",
    color_title: "#f10965"
  },
];

