import { Heading, Box, Text, Stack, SimpleGrid } from "@chakra-ui/react";

export function AcademyFormats() {
  return (
    <Box className="my-history-box">
      &nbsp;
      <Heading
        className="what-i-do-heading"
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
        marginBottom={{ base: "15vw", md: "5vw" }}
        fontSize={{ base: "8vh", md: "12vh" }}
        lineHeight={{ base: "80%", md: "100%" }}
      >
        El formato<span style={{ color: "#C9E500" }}>.</span>
      </Heading>
      <SimpleGrid
        columns={{ base: 1, md: 1 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "5vh", md: "10vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text>
            Es un taller pensado y diseñado para ser muy práctico y del que los
            alumnos se llevarán acciones que puedan poner en práctica desde el
            día siguiente. Habrá dos sesiones con invitados; una en la que
            hablaremos con candidatos/as y contarán que red flags identifican en
            una oferta de empleo, como les gusta que sea el proceso completo… y
            otra sesión en la que personas que han contratado mucho y creado
            equipos para sus empresa nos contaran errores cometidos, buenas
            prácticas…
          </Text>
        </Stack>
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 8, md: 10 }}
        marginBottom={{ base: "20vh", md: "27vh" }}
        marginLeft={{ base: "5%", md: "20%" }}
        marginRight={{ base: "5%", md: "20%" }}
      >
        <Stack spacing={4}>
          <Text className="academy-format-item">para particulares</Text>
          <Stack minH={"340px"}>
            <Text className="academy-formt-title">
              <b>Aprende y conéctate con otros profesionales</b>
            </Text>
            <Text>
              Este formato está diseñado para que cualquier persona interesada
              pueda inscribirse y participar en el taller. Te sumergirás en un
              entorno de aprendizaje dinámico, junto a profesionales de
              diferentes empresas, donde podrás compartir y escuchar
              experiencias, problemas y soluciones que enriquecerán tu
              perspectiva. ¡Un espacio ideal para nutrirte de nuevas ideas y
              enfoques!
            </Text>
            <Text>
              - <b>Plazas limitadas:</b> Mínimo 10 y máximo 15 asistentes.
            </Text>
            <Text>
              - <b>Duración:</b> 10 horas distribuidas en 2 días (5 horas el
              sábado y 5 horas el domingo).
            </Text>
          </Stack>

          <Text className="academy-pricing">
             200<span style={{ color: "#000000" }}> € + IVA</span>
          </Text>
        </Stack>

        <Stack spacing={4}>
          <Text className="academy-format-item">para empresas</Text>
          <Stack minH={"340px"}>
            <Text className="academy-formt-title">
              <b>Alinea a tu equipo en el proceso de contratación</b>
            </Text>
            <Text>
              Este formato exclusivo está diseñado para que diferentes personas
              de una misma empresa, con distintos roles, asistan y salgan
              alineadas en cómo llevar a cabo la contratación de perfiles
              técnicos. Ideal para equipos que desean mejorar su coordinación y
              eficiencia en la selección de talento. Flexible, dado que cada
              empresa es diferente y tiene unas necesidades propias.
            </Text>
            <Text>
              - <b>Personalizado: </b>Cuéntame la necesidad que tenéis en la
              empresa y prepararé una propuesta adaptada tanto en duración como
              en el contenido. Para más información, escribe a{" "}
              <b>
                <a href="mailto:yo@leonardopoza.com">yo@leonardopoza.com</a>
              </b>
            </Text>
          </Stack>

          <Text className="academy-pricing">
            3.500 <span style={{ color: "#000000" }}> € + IVA</span>
          </Text>
        </Stack>
      </SimpleGrid>
    </Box>
  );
}
